<template>
	<div>
		<div
			style="position: absolute; background: #fff; width: 100%; left: 0; top: 0; min-height: 100%; z-index: 999999">
			<div class="print-hidden" hidden>
				<button @click="print" class="btn btn-primary" style="margin: 6px">Print</button>
				<router-link to="/pulmonologist/reports/completed">
					<button class="btn btn-primary" style="margin: 6px">Back</button>
				</router-link>
			</div>
			<div v-if="report">
				<table class="table table-bordered">
					<tbody>
						<tr>
							<td>Patient Name: </td>
							<td>{{ report.patient_name }}</td>
							<td>Reg NO: </td>
							<td>{{ report.reg_no }}</td>
						</tr>
						<tr>
							<td>Refer By: </td>
							<td>{{ report.ref_doctor_name }}</td>
							<td>Age/Gender: </td>
							<td>{{ report.patient_age }}/{{ report.patient_gender }}</td>
						</tr>
						<tr>
							<td>Test Name: </td>
							<td>ECG</td>
							<td>Date: </td>
							<td>{{ format(report.test_date, "DD/MM/YY") }}</td>
						</tr>
					</tbody>
				</table>
				<div>
					<table class="table">
						<tbody>
							<tr>
								<td>BP Systole: </td>
								<td>{{ report.bp_systole }}</td>
								<td>SPO<sub>2</sub></td>
								<td>{{ report.spo2 }}</td>
								<td>Height: </td>
								<td>{{ report.height }}</td>
							</tr>
							<tr>
								<td>BP Diastole: </td>
								<td>{{ report.bp_diastole }}</td>
								<td>Pulse Rate: </td>
								<td>{{ report.pulse_rate }}</td>
								<td>Weight</td>
								<td>{{ report.weight }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-html="report.report"></div>
				<b>Issued By:</b>
				<div>
					<img :src="apiOrigin + '/images/pulmonologist_certificates/' + report?.pulmonologist?.signature_image"
						alt="">
				</div>
				<div style="font-size: 9px; margin-top:8px">
					<b>Disclaimer:</b> It is an online interpretation of medical data based on clinical data. All modern
					machines / procedures have their own limitation. If there is any clinical discrepancy, this investigation
					may be repeated or reassessed by other tests. Patient's identification in online reporting is not
					established, so in no way this report can utilized for any medico legal purpose.
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			report: null,
		}
	},
	async mounted() {
		this.report = await this.get("pulmonologist/load-ecg-report/" + this.$route.params.reportId);
		window.addEventListener('afterprint', this.afterPrint)
		setTimeout(() => {
			this.print();
		}, 100)
	},
	methods: {
		print() {
			window.print();
		},
		afterPrint() {
			window.removeEventListener('afterprint', this.afterPrint);
			this.$router.back();
		}
	}
}
</script>
<style scoped>
@media print {
	.print-hidden {
		display: none;
	}
}

.table td {
	padding: 3px 8px;
}

.table td:nth-child(2n) {
	font-weight: bold;
}

.table td:nth-child(2n+1) {
	background-color: #eee;
}
</style>